import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import clsx from 'clsx';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { Link } from '/src/components/Link';
import { TransitionFade } from '/src/components/animators';
import { Button, makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { LayoutContext } from '/src/layouts';
import { METADATA } from '/src/globals';
import DemoNavigation from '/src/collections/DemoNavigation';
import PageWrapper from '../../components/PageWrapper';
import { useSharedStyles } from '../../styles/shared-styles';
import { COLORS } from '../../globals';

//////////////////////////

const useStyles = makeStyles( theme => ({

  horizontalPaper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& *:not(:first-child)': {
      marginLeft: '1em',
    }
  },
  coloredPaper: {
    color: COLORS.brandWhite,
    background: theme.palette.secondary.main,
  }

}));

//////////////////////////

const propTypes = {
  theme: PropTypes.object,
};

const Theming = (props) => {

  const theme = useTheme();
  const classes = useStyles(props);
  const themeClasses = theme.useStyles(props);
  const sharedClasses = useSharedStyles(props);

  const layoutContext = useContext(LayoutContext);

  //////

  const paperEls = [];
  for( let k = 0; k < 6; k++ ) {
    paperEls.push(
      <Paper
        key = {k}
        elevation = {3}
        className = {clsx(
          classes.coloredPaper,
          sharedClasses.proportionalPaper,
          themeClasses.paperShape,
        )}
        style = {{
          transform: `rotate(${ -15 + Math.random()*30 }deg`,
        }}
        >
        <Typography variant="body1">
          {k+1}
        </Typography>
      </Paper>
    )
  }

  //////
  
  return ( <>
    
    <Helmet>
      <title>Theming{METADATA.titleBarPostfix}</title>
      <meta name="description" content="This page is about..." />
    </Helmet>


    <PageWrapper>

      <TransitionFade>
  
        <Typography variant="h1">
          Theming
        </Typography>


        <Typography variant="h2">
          Changing themes
        </Typography>
        <Typography variant="body1">
          Themes can be changed by changing the theme fed into the Theme Provider (currently used in the layout component).
        </Typography>
        <Button
          type = "button"
          variant = "contained"
          color = "secondary"
          size = "small"
          onClick = {layoutContext.useDefaultTheme}
          >
          Default Theme
        </Button>
        <Button
          type = "button"
          variant = "contained"
          color = "secondary"
          size = "small"
          onClick = {layoutContext.useAlternateTheme}
          >
          Alternate Theme
        </Button>


        <Typography variant="h3">
          Example
        </Typography>
        <Typography variant="body1">
          In the numbered items below, different aspects are controlled by styling in different locations. This is done to utilise differences across themes, shared-styles, and component specific styles. Change the theme above to see the differences in effect.
        </Typography>
        <div className={classes.horizontalPaper}>
          {paperEls}
        </div>
        <ul>
          <li>
            <Typography variant="body1">
              <strong>useSharedStyles: </strong>The margin and padding are controlled by a class defined in shared-styles<br/>
              So it is defined once and consistently applied across themes.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>theme.useStyles: </strong>The rounded edges and positioning are controled by a class defined in each theme<br/>
              So each theme can apply vastly different properties and values.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>useStyles: </strong>The colouring is created by a class defined in this page's component<br/>
              So that it is only relevant on this page.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>style: </strong>The rotation for each one is a random value applied directly to the style tag in JSX.
            </Typography>
          </li>
        </ul>


        <Typography variant="h4">
          useSharedStyles
        </Typography>
        <Typography variant="body1">
          This function is defined in the styles folder and can be used from any component to create JSS classes.<br/>
          As a useStyles function it inherits the theme by default and can be passed the component's props, so it can use both of these to dynamically alter values in each class.
        </Typography>
        <Typography variant="body1">
          It is therefore a good place to define styles that should be shared by different components but that can't be applied in the theme settings or its overides.
        </Typography>
        <Typography variant="body1">
          If using this to change MUI components, consider whether standard methods are more appropriate. Standard methods can allow altering default values, overrides, or adding new variants, etc.
          <Link to="https://next.material-ui.com/customization/theme-components/">Ref: Theme components</Link>
        </Typography>
       
        <Typography variant="h4">
          theme.useStyles
        </Typography>
        <Typography variant="body1">
          This function is defined in each theme and can also be used by any component to create JSS classes.<br/>
          As a useStyles function it inherits the theme by default and can be passed the component's props, so it can use both of these to dynamically alter values in each class.<br/>
        </Typography>
        <Typography variant="body1">
          Since each theme must be seamlessly interchangeable, any class defined in here must be defined in all themes, however, each theme can implement vastly different properties and values.<br/>
          This makes it an easier way to create more complex differences between themes that are shared by multiple components.
        </Typography>

        <Typography variant="h4">
          useStyles
        </Typography>
        <Typography variant="body1">
          This function is defined in the component itself.<br/>
          As a useStyles function it inherits the theme by default and can be passed the component's props, so it can use both of these to dynamically alter values in each class.<br/>
        </Typography>
        <Typography variant="body1">
          This makes it only applicable to a single component, but as most complex elements should be encapsulated into components anyway, this makes it the most common method of definition because it encapsulates the the styles with the component.
        </Typography>

        
      </TransitionFade>
      

      <DemoNavigation/>

    </PageWrapper>
  
  </> )

}

Theming.propTypes = propTypes;
export default Theming;
